import request from './request'

export function groupList(params) {
    return request({
        method: 'POST',
        url: '/Group/groupList',
        data: params,
    })
}
export function editGroup(params) {
    return request({
        method: 'POST',
        url: '/Group/editGroup',
        data: params,
    })
}
export function addGroup(params) {
    return request({
        method: 'POST',
        url: '/Group/addGroup',
        data: params,
    })
}
export function delGroup(params) {
    return request({
        method: 'POST',
        url: '/Group/delGroup',
        data: params,
    })
}
export function groupShopList(params) {
    return request({
        method: 'POST',
        url: '/Group/groupShopList',
        data: params,
    })
}
export function groupShopMemberList(params) {
    return request({
        method: 'POST',
        url: '/Group/groupShopMemberList',
        data: params,
    })
}
export function groupFxsList(params) {
    return request({
        method: 'POST',
        url: '/Group/selectgroupFxs2',
        data: params,
    })
}
export function addGroupShop(params) {
    return request({
        method: 'POST',
        url: '/Group/addGroupShop',
        data: params,
    })
}
export function selectGroupList(params) {
    return request({
        method: 'POST',
        url: '/Group/selectGroupList',
        data: params,
    })
}
export function delGroupShop(params) {
    return request({
        method: 'POST',
        url: '/Group/delGroupShop',
        data: params,
    })
}
export function groupShopMemberInfo(params) {
    return request({
        method: 'POST',
        url: '/Group/groupShopMemberInfo',
        data: params,
    })
}
export function editGroupShopMember(params) {
    return request({
        method: 'POST',
        url: '/Group/editGroupShopMember',
        data: params,
    })
}
export function selectGroupFxs(params) {
    return request({
        method: 'POST',
        url: '/Group/selectGroupFxs',
        data: params,
    })
}
export function editGroupRemarks(params) {
    return request({
        method: 'POST',
        url: '/Group/editGroupRemarks',
        data: params,
    })
}
export function delGroupShopMember(params) {
    return request({
        method: 'POST',
        url: '/Group/delGroupShopMember',
        data: params,
    })
}
export function openGroup(params) {
    return request({
        method: 'POST',
        url: '/Group/openGroup',
        data: params,
    })
}
// 获取商家-红包配置
export function hongbaoData(params) {
    return request({
        method: 'POST',
        url: '/hongbao/index',
        data: params,
    })
}
// 红包配置-开关
export function redOpen(params) {
    return request({
        method: 'POST',
        url: '/hongbao/redOpen',
        data: params,
    })
}
// 红包配置
export function redSave(params) {
    return request({
        method: 'POST',
        url: '/hongbao/redSave',
        data: params,
    })
}
// 红包记录
export function redLog(params) {
    return request({
        method: 'POST',
        url: '/hongbao/redLog',
        data: params,
    })
}
// 红包记录
export function redDownload(params) {
    return request({
        method: 'POST',
        url: '/hongbao/redDownload',
        data: params,
        responseType: 'blob',
        timeOut: 10000000
    })
}
// 退款接口
export function wxRefund(params) {
    return request({
        method: 'POST',
        url: '/Hongbao/wxRefund',
        data: params,
    })
}
